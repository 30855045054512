import React from 'react';
import cls from 'classname';

const LogoSimple = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '48'}
       height={height ? height : '48'}
       fill={color ? color : '#225AEA'}
       className={cls(className, 'icon')}
       style={style}
       viewBox="0 0 48 48"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.2432 9.36228L29.8421 1.88176C26.3544 -0.627252 21.6524 -0.627252 18.1625 1.88176L7.7592 9.36228C5.14896 11.2402 3.6001 14.2585 3.6001 17.4739V30.5261C3.6001 33.7415 5.14674 36.7598 7.7592 38.6377L18.1603 46.1182C21.648 48.6273 26.35 48.6273 29.8399 46.1182L40.241 38.6377C42.8512 36.7598 44.4001 33.7415 44.4001 30.5261V17.4739C44.4001 14.2585 42.8535 11.2402 40.2432 9.36228ZM20.498 5.12597C22.5919 3.62012 25.4127 3.62012 27.5044 5.12597L36.2724 11.4306C36.9062 11.8868 36.9062 12.8279 36.2724 13.2841L27.5044 19.5888C25.4105 21.0946 22.5897 21.0946 20.498 19.5888L11.73 13.2841C11.0962 12.8279 11.0962 11.8868 11.73 11.4306L20.498 5.12597ZM40.4027 30.5239C40.4027 32.4527 39.4743 34.2641 37.9077 35.3913L27.5066 42.8718C25.4127 44.3777 22.5919 44.3777 20.5002 42.8718L10.0991 35.3913C8.53253 34.2641 7.6041 32.4527 7.6041 30.5239V17.4717C7.6041 17.4651 7.6041 17.4584 7.6041 17.4518C7.60631 16.5283 8.65883 15.9969 9.40778 16.535L18.1647 22.833C21.6524 25.342 26.3544 25.342 29.8443 22.833L38.6013 16.535C39.3524 15.9946 40.4027 16.5261 40.405 17.4518C40.405 17.4584 40.405 17.4651 40.405 17.4717V30.5239H40.4027Z"
      />
    <path
      d="M29.7852 26.7681C28.0458 27.1091 26.8914 28.4843 26.0338 30.0655C25.7812 30.5305 25.799 31.1152 26.1912 31.4274C26.4814 31.6577 26.8648 31.6444 27.1462 31.403C28.2231 30.4862 29.4794 29.968 30.2927 29.7909C31.1059 29.6137 32.442 29.565 33.7383 29.9636C34.0795 30.0677 34.4385 29.9171 34.6335 29.5827C34.8994 29.1265 34.7221 28.5818 34.341 28.2496C32.9539 27.0427 31.5512 26.4204 29.7852 26.7681Z"
      />
    <path
      d="M18.2326 26.7681C19.972 27.1091 21.1265 28.4843 21.984 30.0655C22.2366 30.5305 22.2189 31.1152 21.8267 31.4274C21.5364 31.6577 21.1531 31.6444 20.8717 31.403C19.7948 30.4862 18.5384 29.968 17.7252 29.7909C16.912 29.6137 15.5758 29.565 14.2796 29.9636C13.9383 30.0677 13.5794 29.9171 13.3844 29.5827C13.1185 29.1265 13.2957 28.5818 13.6769 28.2496C15.064 27.0427 16.4666 26.4204 18.2326 26.7681Z"
      />
  </svg>
);

export default LogoSimple;
