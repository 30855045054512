import React from 'react';

export default function Visa(props) {
  return (
    <svg
      width={props.width ? props.width : '67'}
      height={props.height ? props.height : '21'}
      viewBox="0 0 67 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.0331 20.6976H23.6055L27.0003 0.429688H32.4276L29.0331 20.6976Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        d="M48.7091 0.926363C47.6385 0.51627 45.9405 0.0634766 43.841 0.0634766C38.481 0.0634766 34.7066 2.82322 34.6834 6.76882C34.6389 9.67988 37.3858 11.2968 39.4403 12.2674C41.5401 13.2592 42.254 13.9066 42.254 14.7907C42.2326 16.1485 40.5571 16.7744 38.9945 16.7744C36.8275 16.7744 35.6664 16.4517 33.902 15.6963L33.1872 15.3725L32.4277 19.9222C33.7007 20.4821 36.046 20.9791 38.481 21.0009C44.176 21.0009 47.8837 18.2838 47.9276 14.0791C47.9493 11.7719 46.4988 10.004 43.3717 8.55936C41.4734 7.632 40.3108 7.00668 40.3108 6.05782C40.3331 5.19523 41.2941 4.31171 43.437 4.31171C45.2015 4.26843 46.4979 4.67795 47.48 5.08776L47.971 5.30298L48.7091 0.926363V0.926363Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        d="M55.9209 13.5174C56.3678 12.353 58.0878 7.8466 58.0878 7.8466C58.0652 7.88988 58.5339 6.66075 58.8019 5.90619L59.1812 7.65259C59.1812 7.65259 60.209 12.5041 60.4322 13.5174C59.584 13.5174 56.9929 13.5174 55.9209 13.5174ZM62.6206 0.429688H58.4223C57.1276 0.429688 56.144 0.795934 55.5855 2.11133L47.5234 20.6973H53.2185C53.2185 20.6973 54.1561 18.1958 54.3576 17.657C54.9824 17.657 60.5225 17.657 61.3262 17.657C61.482 18.3686 61.9738 20.6973 61.9738 20.6973H66.9993L62.6206 0.429688V0.429688Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        d="M19.0741 0.429688L13.7587 14.2505L13.1779 11.4475C12.1952 8.21314 9.11317 4.69913 5.67383 2.95186L10.5426 20.6761H16.2821L24.8134 0.429688H19.0741V0.429688Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        d="M8.82618 0.435547H0.0893809L0 0.845267C6.81525 2.52802 11.3289 6.58423 13.1833 11.4596L11.284 2.14009C10.9713 0.844981 10.0104 0.478268 8.82618 0.435547Z"
        fill="white"
      />
    </svg>
  );
}
