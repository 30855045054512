import React from 'react';
import PropTypes from 'prop-types';

export const SettingsMobile = (
  {
    width,
    height,
    className,
    fill,
    outline,
    theme,
  }) =>
  outline ? (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M26.5911 31.3068C26.6041 31.6743 26.4935 32.0274 26.3458 32.3162C26.03 32.8949 25.4186 33.2157 24.7248 33.2306L23.4567 33.2129C22.7819 33.2061 22.1474 32.8333 21.8181 32.2629C21.6557 31.9817 21.5534 31.6229 21.5622 31.275C21.5753 30.989 21.4833 30.7207 21.3348 30.4636C20.8756 29.6682 19.8381 29.3781 19.0458 29.8034C18.1417 30.3253 17.0074 29.9947 16.474 29.0707L15.8431 27.978C15.305 27.0461 15.5858 25.8984 16.4899 25.3764C17.259 24.911 17.5265 23.8674 17.0673 23.072C16.9142 22.8068 16.7279 22.593 16.4782 22.4693C16.1726 22.303 15.9084 22.027 15.7507 21.7539C15.4167 21.1754 15.4111 20.4395 15.7426 19.8517L16.366 18.7526C16.7054 18.1603 17.3093 17.7902 17.9684 17.8061C18.2784 17.7984 18.6535 17.9033 18.9605 18.0902C19.2005 18.2517 19.4648 18.2919 19.7559 18.3059C20.664 18.3173 21.4132 17.5633 21.4199 16.6489C21.4088 15.5948 22.2588 14.7612 23.3015 14.7698L24.54 14.7725C25.5873 14.7892 26.4377 15.6266 26.4456 16.6933C26.4718 17.6101 27.2475 18.3728 28.1523 18.3967C28.4467 18.3982 28.7243 18.3343 28.9509 18.1821C29.2482 17.989 29.5901 17.9094 29.9141 17.9259C30.5731 17.9418 31.1984 18.2985 31.5528 18.8759L32.2068 20.0088C32.5501 20.6033 32.5307 21.3323 32.2149 21.911C32.0625 22.1918 31.8273 22.4561 31.5068 22.609C31.2616 22.7291 31.0675 22.9376 30.9216 23.1933C30.4899 23.9888 30.7625 25.042 31.5481 25.5311C32.4633 26.0633 32.768 27.2264 32.2541 28.1338L31.6372 29.2077C31.1279 30.123 29.981 30.4424 29.069 29.8977C28.2738 29.4462 27.2462 29.7181 26.8067 30.5182C26.6686 30.7693 26.5713 31.0183 26.5911 31.3068ZM22.6995 21.7161C21.4652 22.4287 21.0716 24.0165 21.7999 25.2779C22.5282 26.5393 24.0861 26.9682 25.3204 26.2556C26.5547 25.5429 26.9701 23.9748 26.2418 22.7134C25.5135 21.452 23.9338 21.0035 22.6995 21.7161Z"
            stroke="#707BA0" strokeWidth="1.89"/>
    </svg>
  ) : (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M19.4547 25.7423C19.472 26.2322 19.3245 26.703 19.1276 27.0881C18.7065 27.8597 17.8913 28.2875 16.9663 28.3073L15.2755 28.2837C14.3758 28.2747 13.5297 27.7776 13.0906 27.017C12.8741 26.642 12.7377 26.1637 12.7495 25.6999C12.7669 25.3185 12.6442 24.9608 12.4463 24.618C11.834 23.5575 10.4506 23.1706 9.39429 23.7376C8.18882 24.4336 6.67639 23.9928 5.96513 22.7608L5.12399 21.3039C4.40654 20.0613 4.78094 18.531 5.98642 17.835C7.0118 17.2145 7.3685 15.823 6.75619 14.7625C6.55209 14.409 6.30365 14.1239 5.97083 13.959C5.56326 13.7372 5.21104 13.3692 5.00076 13.005C4.55545 12.2337 4.54798 11.2524 4.99001 10.4688L5.82114 9.00335C6.27365 8.2136 7.07891 7.72012 7.95766 7.74126C8.37109 7.7311 8.8711 7.87092 9.28056 8.12016C9.60049 8.33538 9.95291 8.38901 10.3411 8.40772C11.5518 8.42287 12.5508 7.41762 12.5596 6.1984C12.5448 4.7929 13.6782 3.68149 15.0685 3.69296L16.7198 3.69656C18.1163 3.71874 19.2501 4.83528 19.2606 6.25755C19.2955 7.48003 20.3298 8.49689 21.5363 8.52881C21.9287 8.53075 22.2989 8.4456 22.601 8.24262C22.9974 7.98516 23.4533 7.87908 23.8853 7.90106C24.764 7.9222 25.5977 8.39786 26.0702 9.16777L26.9422 10.6782C27.3999 11.471 27.3741 12.4429 26.953 13.2145C26.7498 13.5889 26.4362 13.9413 26.0088 14.1452C25.682 14.3054 25.4232 14.5833 25.2286 14.9242C24.6531 15.9849 25.0165 17.3891 26.064 18.0413C27.2842 18.7509 27.6905 20.3017 27.0052 21.5115L26.1827 22.9434C25.5037 24.1639 23.9745 24.5897 22.7585 23.8634C21.6982 23.2615 20.3282 23.624 19.7421 24.6908C19.558 25.0256 19.4283 25.3576 19.4547 25.7423ZM14.2659 12.9547C12.6202 13.9048 12.0954 16.0218 13.0664 17.7037C14.0374 19.3856 16.1146 19.9574 17.7604 19.0073C19.4061 18.0571 19.9599 15.9662 18.9889 14.2843C18.0179 12.6024 15.9116 12.0045 14.2659 12.9547Z"
            fill="white" stroke="white" strokeWidth="1.89"/>
    </svg>
  );

SettingsMobile.defaultProps = {
  width: 24,
  height: 24,
  outline: true,
};

SettingsMobile.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default SettingsMobile;
