import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

const Union = ({ width, height, className, color, outline }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(className, 'icon icon-union')}
  >
    {outline ? (
      <>
        <mask id="path-1-inside-1" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.5001 11.4933C15.0965 11.4933 17.2013 9.38846 17.2013 6.79204C17.2013 4.19563 15.0965 2.09082 12.5001 2.09082C9.90371 2.09082 7.7989 4.19563 7.7989 6.79204C7.7989 9.38846 9.90371 11.4933 12.5001 11.4933ZM12.4998 21.9087C7.80547 21.9087 4 20.7427 4 18.329C4 15.9154 7.80547 13.1681 12.4998 13.1681C17.1941 13.1681 21 15.9154 21 18.329C21 20.7427 17.1941 21.9087 12.4998 21.9087Z"
          />
        </mask>
        <path
          d="M4 18.329H5.92334H4ZM12.4998 13.1681V15.0914V13.1681ZM15.278 6.79204C15.278 8.32623 14.0343 9.56993 12.5001 9.56993V13.4166C16.1588 13.4166 19.1247 10.4507 19.1247 6.79204H15.278ZM12.5001 4.01416C14.0343 4.01416 15.278 5.25786 15.278 6.79204H19.1247C19.1247 3.1334 16.1588 0.16748 12.5001 0.16748V4.01416ZM9.72224 6.79204C9.72224 5.25786 10.9659 4.01416 12.5001 4.01416V0.16748C8.84148 0.16748 5.87556 3.1334 5.87556 6.79204H9.72224ZM12.5001 9.56993C10.9659 9.56993 9.72224 8.32623 9.72224 6.79204H5.87556C5.87556 10.4507 8.84148 13.4166 12.5001 13.4166V9.56993ZM2.07666 18.329C2.07666 20.7753 4.0438 22.1422 5.79744 22.8185C7.64413 23.5307 10.0329 23.8321 12.4998 23.8321V19.9854C10.2723 19.9854 8.41123 19.7037 7.18161 19.2295C6.56901 18.9932 6.22507 18.7516 6.05647 18.5757C5.9211 18.4345 5.92334 18.3706 5.92334 18.329H2.07666ZM12.4998 11.2447C9.79199 11.2447 7.30475 12.0332 5.44968 13.2254C3.7011 14.3492 2.07666 16.1461 2.07666 18.329H5.92334C5.92334 18.0982 6.20163 17.3147 7.52937 16.4614C8.75061 15.6766 10.5133 15.0914 12.4998 15.0914V11.2447ZM22.9233 18.329C22.9233 16.1461 21.2987 14.3491 19.55 13.2254C17.6949 12.0332 15.2075 11.2447 12.4998 11.2447L12.4998 15.0914C14.4863 15.0914 16.2491 15.6766 17.4705 16.4615C18.7984 17.3148 19.0767 18.0983 19.0767 18.329H22.9233ZM12.4998 23.8321C14.9666 23.8321 17.3555 23.5307 19.2023 22.8185C20.9559 22.1423 22.9233 20.7754 22.9233 18.329H19.0767C19.0767 18.3705 19.0789 18.4344 18.9435 18.5757C18.7749 18.7516 18.4309 18.9932 17.8182 19.2295C16.5884 19.7037 14.7272 19.9854 12.4998 19.9854V23.8321Z"
          fill={color}
          mask="url(#path-1-inside-1)"
        />
      </>
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5001 11.4933C15.0965 11.4933 17.2013 9.38846 17.2013 6.79204C17.2013 4.19563 15.0965 2.09082 12.5001 2.09082C9.90371 2.09082 7.7989 4.19563 7.7989 6.79204C7.7989 9.38846 9.90371 11.4933 12.5001 11.4933ZM12.4998 21.9087C7.80547 21.9087 4 20.7427 4 18.329C4 15.9154 7.80547 13.1681 12.4998 13.1681C17.1941 13.1681 21 15.9154 21 18.329C21 20.7427 17.1941 21.9087 12.4998 21.9087Z"
        fill={color}
      />
    )}
  </svg>
  // <svg
  //   width={width}
  //   height={height}
  //   viewBox="0 0 17 20"
  //   fill="none"
  //   xmlns="http://www.w3.org/2000/svg"
  //   className={cls(className, 'icon icon-union')}
  // >
  //   <path
  //     fillRule="evenodd"
  //     clipRule="evenodd"
  //     d="M8.50012 9.49327C11.0965 9.49327 13.2013 7.38846 13.2013 4.79204C13.2013 2.19563 11.0965 0.0908203 8.50012 0.0908203C5.90371 0.0908203 3.7989 2.19563 3.7989 4.79204C3.7989 7.38846 5.90371 9.49327 8.50012 9.49327ZM8.49977 19.9087C3.80547 19.9087 2.11008e-07 18.7427 0 16.329C-2.11009e-07 13.9154 3.80547 11.1681 8.49977 11.1681C13.1941 11.1681 17 13.9154 17 16.329C17 18.7427 13.1941 19.9087 8.49977 19.9087Z"
  //     fill={color}
  //   />
  // </svg>
);

Union.defaultProps = {
  width: 25,
  height: 24,
  color: '#707BA0',
  outline: null,
};

Union.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
  outline: PropTypes.bool,
};

export default Union;
