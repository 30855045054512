import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const SettingIcon = ({ width, height, className }) => (
  <>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cls(className, 'icon icon-setting')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.72808 11.8704C8.73673 12.1154 8.66299 12.3508 8.56451 12.5433C8.35398 12.9291 7.94637 13.143 7.48388 13.1529L6.63847 13.1411C6.18861 13.1366 5.76559 12.8881 5.54603 12.5078C5.43779 12.3203 5.3696 12.0811 5.37546 11.8492C5.38416 11.6585 5.32283 11.4797 5.22388 11.3083C4.91772 10.778 4.22603 10.5846 3.69788 10.8681C3.09514 11.2161 2.33893 10.9956 1.9833 10.3797L1.56273 9.65122C1.204 9.02989 1.3912 8.26478 1.99394 7.91679C2.50663 7.6065 2.68498 6.91077 2.37883 6.3805C2.27678 6.20374 2.15256 6.06121 1.98615 5.97875C1.78236 5.86787 1.60625 5.68388 1.50111 5.50176C1.27846 5.11611 1.27472 4.62549 1.49574 4.23364L1.9113 3.50094C2.13756 3.10607 2.54019 2.85933 2.97956 2.8699C3.18628 2.86482 3.43628 2.93473 3.64101 3.05935C3.80098 3.16696 3.97719 3.19377 4.17127 3.20313C4.77663 3.2107 5.27612 2.70808 5.28056 2.09847C5.27315 1.39572 5.83983 0.840014 6.53497 0.845747L7.36063 0.847549C8.05886 0.858639 8.62577 1.41691 8.63102 2.12804C8.64849 2.73928 9.16565 3.24771 9.76887 3.26367C9.9651 3.26464 10.1502 3.22207 10.3012 3.12058C10.4994 2.99185 10.7274 2.93881 10.9434 2.9498C11.3827 2.96037 11.7996 3.1982 12.0358 3.58315L12.4719 4.33839C12.7007 4.73475 12.6878 5.22071 12.4772 5.60651C12.3757 5.7937 12.2188 5.96993 12.0052 6.07188C11.8417 6.15195 11.7123 6.29093 11.6151 6.46136C11.3273 6.99174 11.509 7.69383 12.0327 8.01994C12.6429 8.3747 12.846 9.15014 12.5034 9.75502L12.0921 10.471C11.7526 11.0812 10.988 11.2941 10.38 10.931C9.84984 10.63 9.16481 10.8112 8.87178 11.3447C8.77975 11.5121 8.71487 11.6781 8.72808 11.8704ZM6.13368 5.4766C5.31082 5.95169 5.04841 7.01018 5.53392 7.85112C6.01944 8.69205 7.05805 8.97798 7.88091 8.50289C8.70378 8.02781 8.98071 6.98236 8.49519 6.14143C8.00968 5.30049 6.95655 5.00152 6.13368 5.4766Z"
        stroke="#225aea"
        strokeWidth="1.2"
      />
    </svg>
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cls(className, 'icon icon-setting-mobile')}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3028 17.4945C13.3143 17.8211 13.216 18.135 13.0847 18.3917C12.804 18.9061 12.2605 19.1913 11.6439 19.2046L10.5167 19.1888C9.91685 19.1828 9.35282 18.8514 9.06007 18.3443C8.91575 18.0944 8.82483 17.7755 8.83265 17.4663C8.84424 17.212 8.76248 16.9735 8.63053 16.745C8.22233 16.038 7.30007 15.7801 6.59587 16.1581C5.79222 16.6221 4.78394 16.3282 4.30976 15.5069L3.749 14.5356C3.2707 13.7072 3.5203 12.687 4.32395 12.223C5.00754 11.8093 5.24534 10.8817 4.83714 10.1747C4.70107 9.93898 4.53544 9.74893 4.31356 9.63898C4.04185 9.49114 3.80704 9.24582 3.66685 9.003C3.36997 8.4888 3.365 7.83464 3.65968 7.31217L4.21377 6.33524C4.51544 5.80874 5.05228 5.47975 5.63811 5.49385C5.91373 5.48708 6.24708 5.58029 6.52005 5.74644C6.73333 5.88993 6.96828 5.92568 7.22706 5.93815C8.03421 5.94826 8.70019 5.27809 8.70611 4.46528C8.69624 3.52827 9.4518 2.78734 10.3787 2.79498L11.4795 2.79738C12.4105 2.81217 13.1664 3.55653 13.1734 4.50471C13.1967 5.3197 13.8862 5.9976 14.6905 6.01888C14.9522 6.02017 15.1989 5.96341 15.4003 5.82809C15.6646 5.65645 15.9685 5.58573 16.2565 5.60038C16.8424 5.61447 17.3982 5.93158 17.7131 6.44485L18.2945 7.45183C18.5996 7.98032 18.5824 8.62827 18.3017 9.14266C18.1662 9.39225 17.9572 9.62723 17.6722 9.76316C17.4543 9.86991 17.2818 10.0552 17.1521 10.2825C16.7684 10.9896 17.0107 11.9258 17.709 12.3606C18.5225 12.8336 18.7933 13.8675 18.3365 14.674L17.7882 15.6286C17.3355 16.4422 16.316 16.7261 15.5054 16.2419C14.7985 15.8406 13.8851 16.0823 13.4944 16.7935C13.3717 17.0167 13.2852 17.2381 13.3028 17.4945ZM9.84361 8.96946C8.74645 9.6029 8.39658 11.0142 9.04393 12.1355C9.69128 13.2567 11.0761 13.638 12.1732 13.0045C13.2704 12.3711 13.6396 10.9771 12.9923 9.85589C12.3449 8.73464 10.9408 8.33601 9.84361 8.96946Z"
        stroke="#225aea"
        strokeWidth="1.92"
      />
    </svg>
  </>
);

SettingIcon.defaultProps = {
  width: 14,
  height: 14,
};

SettingIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
};

export default SettingIcon;
