import React from 'react';
import cls from 'classname';

const LockedBlock = ({width, height, color, className, style, ...props}) => (
  <svg width={width ? width : '64'}
       height={height ? height : '64'}
       viewBox="0 0 64 64"
       fill="none"
       className={cls(className, 'icon')}
       style={style}
       xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" rx="6" fill="#225AEA"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M26.4544 28H37.5435C38.3049 28 38.9618 28 39.5029 28.0408C40.0739 28.0838 40.6419 28.1789 41.1884 28.436C42.0038 28.8195 42.6667 29.4314 43.0822 30.184C43.3607 30.6885 43.4637 31.2128 43.5103 31.74C43.5545 32.2394 43.5545 32.8458 43.5545 33.5486V39.7849C43.5545 40.4877 43.5545 41.094 43.5103 41.5934C43.4637 42.1205 43.3607 42.6448 43.0822 43.1493C42.6667 43.9019 42.0038 44.5139 41.1884 44.8974C40.6419 45.1544 40.0739 45.2495 39.5029 45.2926C38.9618 45.3334 38.3049 45.3334 37.5435 45.3333H26.4543C25.6929 45.3334 25.036 45.3334 24.495 45.2926C23.9239 45.2495 23.356 45.1544 22.8094 44.8974C21.994 44.5139 21.3311 43.9019 20.9157 43.1493C20.6372 42.6448 20.5342 42.1205 20.4875 41.5934C20.4433 41.094 20.4433 40.4876 20.4434 39.7848V33.5486C20.4433 32.8457 20.4433 32.2394 20.4875 31.74C20.5342 31.2128 20.6372 30.6885 20.9157 30.184C21.3311 29.4314 21.994 28.8195 22.8094 28.436C23.356 28.1789 23.9239 28.0838 24.495 28.0408C25.036 28 25.693 28 26.4544 28ZM31.9989 36C31.2012 36 30.5545 36.597 30.5545 37.3333V38.6667C30.5545 39.403 31.2012 40 31.9989 40C32.7967 40 33.4434 39.403 33.4434 38.6667V37.3333C33.4434 36.597 32.7967 36 31.9989 36Z"
          fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M26.2207 24C26.2207 21.0545 28.8075 18.6667 31.9985 18.6667C35.1895 18.6667 37.7763 21.0545 37.7763 24V28H34.8874V24C34.8874 22.5273 33.594 21.3334 31.9985 21.3334C30.403 21.3334 29.1096 22.5273 29.1096 24V28H26.2207V24Z"
          fill="white"/>
  </svg>

);

export default LockedBlock;
