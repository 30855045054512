import React from 'react';
import cls from 'classname';

const AlertTriangle = ({ width, height, color, className, style, ...props }) => (
  <svg width={width ? width : '96'}
       height={height ? height : '96'}
       viewBox="0 0 96 96"
       fill={color ? color : '#F6B60E'}
       style={style}
       className={cls(className, 'icon')}
       xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M90.2404 65.2006L59.5604 14.3206C58.3198 12.3798 56.6106 10.7827 54.5903 9.67633C52.5701 8.56999 50.3038 7.99008 48.0004 7.99008C45.697 7.99008 43.4307 8.56999 41.4105 9.67633C39.3902 10.7827 37.681 12.3798 36.4404 14.3206L5.7604 65.2006C4.67661 67.0072 4.08756 69.0673 4.05245 71.1738C4.01734 73.2803 4.53742 75.3588 5.5604 77.2006C6.74312 79.2736 8.45504 80.9955 10.5212 82.1902C12.5874 83.385 14.9337 84.0097 17.3204 84.0006H78.6804C81.0514 84.0259 83.3871 83.4253 85.4519 82.2595C87.5167 81.0937 89.2374 79.4039 90.4404 77.3606C91.4934 75.4997 92.0301 73.3912 91.995 71.2534C91.9598 69.1155 91.3541 67.0258 90.2404 65.2006ZM48.0004 68.0006C47.2093 68.0006 46.4359 67.766 45.7781 67.3265C45.1203 66.8869 44.6076 66.2622 44.3049 65.5313C44.0021 64.8004 43.9229 63.9961 44.0773 63.2202C44.2316 62.4443 44.6126 61.7316 45.172 61.1721C45.7314 60.6127 46.4441 60.2318 47.22 60.0774C47.996 59.9231 48.8002 60.0023 49.5311 60.3051C50.262 60.6078 50.8868 61.1205 51.3263 61.7783C51.7658 62.4361 52.0004 63.2094 52.0004 64.0006C52.0004 65.0614 51.579 66.0789 50.8288 66.829C50.0787 67.5792 49.0613 68.0006 48.0004 68.0006ZM52.0004 52.0006C52.0004 53.0614 51.579 54.0789 50.8288 54.829C50.0787 55.5791 49.0613 56.0006 48.0004 56.0006C46.9395 56.0006 45.9221 55.5791 45.172 54.829C44.4218 54.0789 44.0004 53.0614 44.0004 52.0006V36.0006C44.0004 34.9397 44.4218 33.9223 45.172 33.1721C45.9221 32.422 46.9395 32.0006 48.0004 32.0006C49.0613 32.0006 50.0787 32.422 50.8288 33.1721C51.579 33.9223 52.0004 34.9397 52.0004 36.0006V52.0006Z"
      />
  </svg>

);

export default AlertTriangle;
