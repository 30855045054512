import React from 'react';

export default function DeleteBasket(props) {
  return (
    <svg
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      viewBox="0 0 24 24"
      fill="none"
      className="icon icon-delete"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4641 3.60442L19.3752 3.60442C19.996 3.60442 20.4992 4.10766 20.4992 4.72844C20.4992 5.34922 19.996 5.85246 19.3752 5.85246L4.62475 5.85246C4.00397 5.85246 3.50073 5.34922 3.50073 4.72844C3.50073 4.10766 4.00397 3.60442 4.62475 3.60442L8.5358 3.60442C8.9651 2.64994 9.92432 1.98535 11.0388 1.98535H12.9611C14.0755 1.98535 15.0348 2.64994 15.4641 3.60442Z"
        fill="#D71313"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4389 19.2683C18.1985 20.8482 16.8401 22.0155 15.242 22.0155H9.07109C7.47307 22.0155 6.11468 20.8482 5.87427 19.2683L4.33891 9.17879C4.16021 8.00448 5.06917 6.94673 6.257 6.94673H18.0561C19.244 6.94673 20.1529 8.00448 19.9742 9.17879L18.4389 19.2683ZM15.242 19.7674C15.7291 19.7674 16.1431 19.4117 16.2164 18.9301L17.5667 10.0569C17.6357 9.6033 17.2846 9.19477 16.8259 9.19477H7.48726C7.02849 9.19477 6.67743 9.6033 6.74644 10.0569L8.09672 18.9301C8.17 19.4117 8.58403 19.7674 9.07109 19.7674H15.242Z"
        fill="#D71313"
      />
    </svg>
  );
}
