import React from 'react';
import cls from 'classname';

const DatabaseCurrency = (props) => (
  <svg width={props.width ? props.width : '24'}
       height={props.height ? props.height : '24'}
       fill={props.color ? props.color : '#707BA0'}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
       className={cls(props.className, 'icon icon-database-filter')}
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12.6016 20C17.0198 20 20.6016 16.4183 20.6016 12C20.6016 7.58172 17.0198 4 12.6016 4C8.18328 4 4.60156 7.58172 4.60156 12C4.60156 16.4183 8.18328 20 12.6016 20ZM12.6016 22C18.1244 22 22.6016 17.5228 22.6016 12C22.6016 6.47715 18.1244 2 12.6016 2C7.07871 2 2.60156 6.47715 2.60156 12C2.60156 17.5228 7.07871 22 12.6016 22Z"
    />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M11.6016 7C11.6016 6.44772 12.0493 6 12.6016 6C13.1538 6 13.6016 6.44772 13.6016 7C15.2584 7 16.6016 8.34315 16.6016 10C16.6016 10.5523 16.1538 11 15.6016 11C15.0493 11 14.6016 10.5523 14.6016 10C14.6016 9.44772 14.1538 9 13.6016 9H12.6016H11.345C10.9344 9 10.6016 9.33284 10.6016 9.74342C10.6016 10.0634 10.8063 10.3475 11.1099 10.4487L14.7257 11.6539C15.8459 12.0274 16.6016 13.0757 16.6016 14.2566C16.6016 15.7717 15.3733 17 13.8581 17H13.6016C13.6016 17.5523 13.1538 18 12.6016 18C12.0493 18 11.6016 17.5523 11.6016 17C9.94471 17 8.60156 15.6569 8.60156 14C8.60156 13.4477 9.04928 13 9.60156 13C10.1538 13 10.6016 13.4477 10.6016 14C10.6016 14.5523 11.0493 15 11.6016 15H12.6016H13.8581C14.2687 15 14.6016 14.6672 14.6016 14.2566C14.6016 13.9366 14.3968 13.6525 14.0932 13.5513L10.4774 12.346C9.35718 11.9726 8.60156 10.9243 8.60156 9.74342C8.60156 8.22827 9.82983 7 11.345 7L11.6016 7Z"
    />
  </svg>
);
export default DatabaseCurrency;
