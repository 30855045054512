import React from 'react';

export default function AmericanExpress(props) {
  return (
    <svg width={props.width ? props.width : '47'}
         height={props.height ? props.height : '27'}
         viewBox="0 0 47 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_19082_272105)">
        <path d="M0 3C0 1.34314 1.34315 0 3 0H43.9977C45.6545 0 46.9977 1.34315 46.9977 3V14.5754V16.6636V18.522V24C46.9977 25.6569 45.6545 27 43.9977 27H3C1.34315 27 0 25.6569 0 24V13.2599V11.9571V11.3805V3Z" fill="url(#paint0_linear_19082_272105)"/>
        <path d="M0.2 3C0.2 1.4536 1.4536 0.2 3 0.2H43.9977C45.5441 0.2 46.7977 1.4536 46.7977 3V14.5754V16.6636V18.522V24C46.7977 25.5464 45.5441 26.8 43.9977 26.8H3C1.4536 26.8 0.2 25.5464 0.2 24V13.2599V11.9571V11.3805V3Z" stroke="url(#paint1_linear_19082_272105)" strokeWidth="0.4"/>
      </g>
      <path d="M17.8327 16.1651L18.6416 15.2H46.8V21.4023C46.7923 21.4065 46.7842 21.4109 46.7756 21.4155C46.6975 21.4575 46.584 21.514 46.4448 21.5708C46.1648 21.6851 45.7901 21.7966 45.3945 21.8H29.7329L28.8446 20.7662L28.4929 20.3569V20.8966V21.8H25.7858V20.1164V19.7572L25.4818 19.9455L25.4816 19.9457L25.4815 19.9458L25.481 19.946L25.4808 19.9462L25.4807 19.9462L25.4712 19.9514C25.4615 19.9566 25.4453 19.965 25.4222 19.9754C25.3762 19.9964 25.3031 20.026 25.2018 20.0562C24.9994 20.1164 24.6832 20.1793 24.2438 20.1793H23.1862H22.9862V20.3793V21.8H18.5784L17.7987 20.8169L17.6432 20.6208L17.4862 20.8157L16.6936 21.8H9.2V15.2H16.7432L17.5241 16.1626L17.677 16.351L17.8327 16.1651Z" fill="url(#paint2_linear_19082_272105)" fillOpacity="0.3" stroke="url(#paint3_linear_19082_272105)" strokeWidth="0.4"/>
      <path d="M5.99395 5.1873V7.3004L5.6103 7.37958L4.66505 5.1873H1.88963L0.2 9.15744V12.2055H2.03538L2.44883 11.1624L2.4989 11.0361H2.63476H3.87956H4.01506L4.0653 11.162L4.48188 12.2055H21.8083V11.2361V10.6694L22.164 11.1106L23.0467 12.2055H27.5008V11.5822V11.092L5.99395 5.1873ZM5.99395 5.1873H9.43078L9.97724 6.72607L10.1686 7.26501L10.3548 6.72424L10.884 5.1873H27.5008V5.82779V6.2912L27.8376 5.97361L27.8377 5.97359L27.8378 5.9735L27.8378 5.97342L27.8379 5.97332L27.8386 5.97268L27.8444 5.96747C27.8499 5.96254 27.8588 5.95476 27.8709 5.94454C27.8951 5.92409 27.9323 5.89392 27.9819 5.85723C28.0812 5.78379 28.2294 5.68465 28.4216 5.58517C28.8061 5.38621 29.3637 5.18737 30.0571 5.1873C30.0573 5.1873 30.0574 5.1873 30.0575 5.1873L35.4282 5.20622L36.3566 7.36841L36.7403 7.2895V5.1873H39.5947L40.4055 6.40919L40.7722 6.96169V6.2986V5.1873H43.5628V12.2055H40.6822L39.9072 10.9829L39.5383 10.4009V11.09V12.2055H35.2286L34.8117 11.1619L34.7614 11.0361H34.6259H33.3772H33.2409L33.191 11.163L32.7813 12.2055H29.7674C29.1812 12.2055 28.6988 12.0141 28.3608 11.8203C28.192 11.7236 28.0607 11.6271 27.9723 11.5554C27.9281 11.5197 27.8949 11.4902 27.8732 11.4703C27.8623 11.4603 27.8544 11.4527 27.8494 11.4479L27.8443 11.4429L27.8437 11.4423L27.8436 11.4422L27.8435 11.4421L27.8434 11.442L5.99395 5.1873Z" fill="url(#paint4_linear_19082_272105)" fillOpacity="0.3" stroke="url(#paint5_linear_19082_272105)" strokeWidth="0.4"/>
      <path d="M2.3801 5.90137L0.00976562 11.4559H1.55298L1.99033 10.3436H4.53289L4.96798 11.4559H6.54519L4.17711 5.90137H2.3801ZM3.25708 7.19407L4.03209 9.13771H2.47981L3.25708 7.19407Z" fill="white" fillOpacity="0.8"/>
      <path d="M6.70898 11.4559V5.90137L8.90197 5.90958L10.1775 9.49078L11.4225 5.90137H13.5979V11.4559H12.2201V7.36308L10.7596 11.4559H9.55132L8.08677 7.36308V11.4559H6.70898Z" fill="white" fillOpacity="0.8"/>
      <path d="M14.5391 11.4559V5.90137H19.035V7.14383H15.9314V8.09394H18.9625V9.26332H15.9314V10.25H19.035V11.4559H14.5391Z" fill="white" fillOpacity="0.8"/>
      <path d="M19.832 5.90137V11.4559H21.2098V9.48257H21.7899L23.4419 11.4559H25.1256L23.3128 9.40949C24.0568 9.34621 24.8243 8.70261 24.8243 7.7034C24.8243 6.53453 23.914 5.90137 22.8981 5.90137H19.832ZM21.2098 7.14383H22.7848C23.1626 7.14383 23.4374 7.44168 23.4374 7.72851C23.4374 8.09753 23.0813 8.3132 22.8052 8.3132H21.2098V7.14383Z" fill="white" fillOpacity="0.8"/>
      <path d="M26.7935 11.4559H25.3867V5.90137H26.7935V11.4559Z" fill="white" fillOpacity="0.8"/>
      <path d="M30.1317 11.4559H29.8281C28.3588 11.4559 27.4668 10.2893 27.4668 8.70147C27.4668 7.07445 28.3489 5.90137 30.2042 5.90137H31.7271V7.21691H30.1486C29.3954 7.21691 28.8627 7.80932 28.8627 8.71517C28.8627 9.79087 29.4718 10.2427 30.3493 10.2427H30.7118L30.1317 11.4559Z" fill="white" fillOpacity="0.8"/>
      <path d="M33.1262 5.90137L30.7559 11.4559H32.2991L32.7364 10.3436H35.279L35.7141 11.4559H37.2913L34.9232 5.90137H33.1262ZM34.0032 7.19407L34.7782 9.13771H33.2259L34.0032 7.19407Z" fill="white" fillOpacity="0.8"/>
      <path d="M37.457 11.4559V5.90137H39.2087L41.4454 9.39122V5.90137H42.8231V11.4559H41.1281L38.8348 7.87469V11.4559H37.457Z" fill="white" fillOpacity="0.8"/>
      <path d="M10.043 21.1756V15.6211H14.5389V16.8636H11.4353V17.8137H14.4664V18.983H11.4353V19.9697H14.5389V21.1756H10.043Z" fill="white" fillOpacity="0.8"/>
      <path d="M32.0742 21.1756V15.6211H36.5701V16.8636H33.4665V17.8137H36.4831V18.983H33.4665V19.9697H36.5701V21.1756H32.0742Z" fill="white" fillOpacity="0.8"/>
      <path d="M14.7123 21.1756L16.9013 18.4326L14.6602 15.6211H16.396L17.7307 17.3592L19.07 15.6211H20.7378L18.5261 18.3984L20.7192 21.1756H18.9836L17.6877 19.4649L16.4232 21.1756H14.7123Z" fill="white" fillOpacity="0.8"/>
      <path d="M20.8867 15.6211V21.1756H22.3008V19.4216H23.7511C24.9782 19.4216 25.9084 18.7654 25.9084 17.4894C25.9084 16.4323 25.1789 15.6211 23.9301 15.6211H20.8867ZM22.3008 16.8773H23.8281C24.2246 16.8773 24.5079 17.1222 24.5079 17.5168C24.5079 17.8875 24.226 18.1563 23.8236 18.1563H22.3008V16.8773Z" fill="white" fillOpacity="0.8"/>
      <path d="M26.5039 15.6211V21.1756H27.8817V19.2023H28.4618L30.1138 21.1756H31.7975L29.9846 19.1292C30.7286 19.0659 31.4961 18.4223 31.4961 17.4231C31.4961 16.2543 30.5859 15.6211 29.5699 15.6211H26.5039ZM27.8817 16.8636H29.4566C29.8344 16.8636 30.1093 17.1614 30.1093 17.4482C30.1093 17.8173 29.7532 18.0329 29.477 18.0329H27.8817V16.8636Z" fill="white" fillOpacity="0.8"/>
      <path d="M37.2062 21.1756V19.9697H39.9636C40.3716 19.9697 40.5482 19.7475 40.5482 19.5038C40.5482 19.2703 40.3721 19.0342 39.9636 19.0342H38.7175C37.6345 19.0342 37.0312 18.3691 37.0312 17.3706C37.0312 16.48 37.5836 15.6211 39.1931 15.6211H41.8762L41.296 16.8709H38.9756C38.532 16.8709 38.3954 17.1055 38.3954 17.3295C38.3954 17.5597 38.5642 17.8137 38.903 17.8137H40.2083C41.4157 17.8137 41.9396 18.5039 41.9396 19.4079C41.9396 20.3797 41.3558 21.1756 40.1426 21.1756H37.2062Z" fill="white" fillOpacity="0.8"/>
      <path d="M42.2648 21.1756V19.9697H45.0222C45.4302 19.9697 45.6068 19.7475 45.6068 19.5038C45.6068 19.2703 45.4307 19.0342 45.0222 19.0342H43.7761C42.693 19.0342 42.0898 18.3691 42.0898 17.3706C42.0898 16.48 42.6422 15.6211 44.2517 15.6211H46.9348L46.3546 16.8709H44.0342C43.5906 16.8709 43.454 17.1055 43.454 17.3295C43.454 17.5597 43.6228 17.8137 43.9616 17.8137H45.2669C46.4743 17.8137 46.9982 18.5039 46.9982 19.4079C46.9982 20.3797 46.4144 21.1756 45.2012 21.1756H42.2648Z" fill="white" fillOpacity="0.8"/>
      <defs>
        <filter id="filter0_b_19082_272105" x="-11" y="-11" width="68.998" height="49" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feGaussianBlur in="BackgroundImage" stdDeviation="5.5"/>
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_19082_272105"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_19082_272105" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_19082_272105" x1="23.4988" y1="0" x2="23.4988" y2="27" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.08"/>
          <stop offset="1" stopColor="white" stopOpacity="0.3"/>
        </linearGradient>
        <linearGradient id="paint1_linear_19082_272105" x1="13.4279" y1="47.25" x2="44.864" y2="-32.3532" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="0.359372" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_19082_272105" x1="23.9697" y1="22.3281" x2="24.8024" y2="11.0572" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0.69"/>
        </linearGradient>
        <linearGradient id="paint3_linear_19082_272105" x1="53.5" y1="34" x2="56.0959" y2="21.6437" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="0.711413" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint4_linear_19082_272105" x1="17.2399" y1="12.7532" x2="18.0526" y2="0.798974" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0.69"/>
        </linearGradient>
        <linearGradient id="paint5_linear_19082_272105" x1="55" y1="17" x2="52.6332" y2="5.11385" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
      </defs>
    </svg>

  );
}
