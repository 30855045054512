import React from 'react';
import cls from 'classname';

const DatabaseGlobe = (props) => (
  <svg width={props.width ? props.width : '24'}
       height={props.height ? props.height : '24'}
       fill={props.color ? props.color : '#707BA0'}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
       className={cls(props.className, 'icon icon-database-filter')}
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M17.3737 13H21.3984V11H17.3737C17.1229 5.94668 14.9911 2 12.3984 2C9.80576 2 7.67399 5.94668 7.42313 11H3.39844V13H7.42313C7.67399 18.0533 9.80576 22 12.3984 22C14.9911 22 17.1229 18.0533 17.3737 13ZM15.371 13C15.2555 15.094 14.7842 16.8986 14.1451 18.1766C13.3466 19.7737 12.6135 20 12.3984 20C12.1834 20 11.4503 19.7737 10.6518 18.1766C10.0127 16.8986 9.54139 15.094 9.42583 13H15.371ZM9.42583 11C9.54139 8.90602 10.0127 7.10143 10.6518 5.82336C11.4503 4.22632 12.1834 4 12.3984 4C12.6135 4 13.3466 4.22632 14.1451 5.82336C14.7842 7.10143 15.2555 8.90602 15.371 11H9.42583Z"
    />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M12.3984 20C16.8167 20 20.3984 16.4183 20.3984 12C20.3984 7.58172 16.8167 4 12.3984 4C7.98016 4 4.39844 7.58172 4.39844 12C4.39844 16.4183 7.98016 20 12.3984 20ZM12.3984 22C17.9213 22 22.3984 17.5228 22.3984 12C22.3984 6.47715 17.9213 2 12.3984 2C6.87559 2 2.39844 6.47715 2.39844 12C2.39844 17.5228 6.87559 22 12.3984 22Z"
    />
  </svg>
);
export default DatabaseGlobe;
