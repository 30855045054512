import React from 'react';
import cls from 'classname';

const Orders = ({ className }) => (
  <svg width="24"
       height="24"
       fill="#225aea"
       className={cls('icon icon-orders', className)}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M7.73723 2.3284H10.2411V2.328H11.1882H16.2645C18.2868 2.328 20.0076 3.80107 20.3195 5.79913L21.944 16.2057C22.3923 19.0774 20.1717 21.6716 17.2652 21.6716H13.7607V21.672H12.8136H6.73658C3.83002 21.672 1.60941 19.0778 2.05771 16.206L3.68221 5.79952C3.99411 3.80147 5.71498 2.3284 7.73723 2.3284ZM11.8665 19.7774V19.7778H6.73658C4.99264 19.7778 3.66028 18.2213 3.92926 16.4982L5.55376 6.09168C5.7217 5.0158 6.64832 4.22261 7.73723 4.22261H11.1882H12.1353V4.22222H16.2645C17.3534 4.22222 18.28 5.01541 18.448 6.09128L20.0725 16.4978C20.3415 18.2209 19.0091 19.7774 17.2652 19.7774H12.8136H11.8665ZM9.51649 5.84451C9.51649 5.32144 9.09245 4.8974 8.56938 4.8974C8.04631 4.8974 7.62227 5.32144 7.62227 5.84451V6.76999C7.62227 8.97374 9.63625 10.5373 11.8252 10.5373H12.1764C14.3653 10.5373 16.3792 8.97374 16.3792 6.76999V5.84451C16.3792 5.32144 15.9552 4.8974 15.4321 4.8974C14.9091 4.8974 14.485 5.32144 14.485 5.84451V6.76999C14.485 7.68134 13.5837 8.64308 12.1764 8.64308H11.8252C10.4178 8.64308 9.51649 7.68134 9.51649 6.76999V5.84451Z"
          />
  </svg>
);

export default Orders;
