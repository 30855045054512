import React from 'react';

export default function SyncArrows(props) {
  return (
    <svg
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6596 10.37C21.6932 10.3109 21.7168 10.2467 21.7296 10.18L22.4796 6.18C22.5273 5.91478 22.4678 5.64146 22.314 5.42017C22.1602 5.19888 21.9248 5.04774 21.6596 5C21.3944 4.95226 21.1211 5.01183 20.8998 5.16561C20.6785 5.31939 20.5273 5.55478 20.4796 5.82L20.1096 7.82C19.2772 6.29992 18.0332 5.04521 16.5204 4.19978C15.0075 3.35436 13.287 2.95244 11.5561 3.04013C9.8253 3.12782 8.15421 3.70157 6.73454 4.69557C5.31486 5.68957 4.20408 7.06357 3.52959 8.66C3.43065 8.90537 3.43298 9.17995 3.53606 9.42361C3.63915 9.66726 3.83459 9.86014 4.07959 9.96C4.20165 10.0105 4.3325 10.0364 4.46461 10.0361C4.59671 10.0357 4.72744 10.0092 4.84925 9.95809C4.97105 9.90696 5.08152 9.83219 5.17426 9.73811C5.26701 9.64404 5.34019 9.53252 5.38959 9.41C5.92812 8.0868 6.85435 6.95747 8.04657 6.1704C9.23878 5.38332 10.6412 4.97532 12.0696 5C13.3329 4.99411 14.5753 5.3221 15.6711 5.95076C16.7668 6.57943 17.6771 7.48645 18.3096 8.58L16.6596 8.31C16.5283 8.28899 16.3941 8.29405 16.2647 8.32489C16.1354 8.35573 16.0133 8.41175 15.9056 8.48976C15.7979 8.56776 15.7066 8.66621 15.6369 8.7795C15.5673 8.89278 15.5206 9.01868 15.4996 9.15C15.4786 9.28132 15.4836 9.4155 15.5145 9.54486C15.5453 9.67423 15.6013 9.79625 15.6793 9.90397C15.7573 10.0117 15.8558 10.103 15.9691 10.1726C16.0824 10.2423 16.2083 10.289 16.3396 10.31L20.5896 11.02H20.7496C20.8657 11.0214 20.981 11.001 21.0896 10.96C21.1262 10.9461 21.1601 10.9258 21.1896 10.9C21.2613 10.8733 21.3287 10.8363 21.3896 10.79L21.4696 10.69C21.5215 10.6414 21.5684 10.5878 21.6096 10.53C21.6339 10.4794 21.6508 10.4255 21.6596 10.37Z"
        fill="#225aea"
      />
      <path
        d="M19.8802 14.0699C19.7575 14.0197 19.6261 13.9943 19.4935 13.9953C19.3609 13.9963 19.2299 14.0237 19.108 14.0758C18.9861 14.1279 18.8757 14.2037 18.7834 14.2989C18.6911 14.394 18.6186 14.5065 18.5702 14.6299C18.0279 15.9392 17.1051 17.0557 15.9213 17.8347C14.7375 18.6138 13.3472 19.0197 11.9302 18.9999C10.6669 19.0058 9.42446 18.6779 8.32868 18.0492C7.23291 17.4205 6.32269 16.5135 5.69017 15.4199L7.34017 15.6899H7.50017C7.76539 15.7112 8.02817 15.6262 8.23071 15.4536C8.43325 15.2811 8.55895 15.0352 8.58017 14.7699C8.60139 14.5047 8.51638 14.2419 8.34384 14.0394C8.17131 13.8369 7.92539 13.7112 7.66017 13.6899L3.41017 12.9999C3.30109 12.9798 3.18925 12.9798 3.08017 12.9999H3.00017C2.887 13.03 2.77909 13.0773 2.68017 13.1399C2.61275 13.192 2.55226 13.2525 2.50017 13.3199L2.41017 13.4199C2.37926 13.4802 2.35576 13.544 2.34017 13.6099C2.30513 13.6611 2.28127 13.719 2.27017 13.7799L1.52017 17.7799C1.48805 17.9129 1.48353 18.0509 1.50688 18.1857C1.53023 18.3204 1.58097 18.4489 1.65594 18.5632C1.73092 18.6775 1.82855 18.7753 1.94279 18.8504C2.05704 18.9255 2.18548 18.9764 2.32017 18.9999H2.50017C2.73696 19.0038 2.96743 18.9235 3.15056 18.7733C3.33368 18.6232 3.45757 18.4129 3.50017 18.1799L3.87017 16.1799C4.70169 17.7004 5.94488 18.9557 7.45718 19.802C8.96948 20.6482 10.6897 21.0511 12.4205 20.9645C14.1513 20.8778 15.8226 20.3051 17.2428 19.3121C18.6631 18.319 19.7747 16.9458 20.4502 15.3499C20.5418 15.1045 20.5334 14.8328 20.4268 14.5934C20.3202 14.354 20.1239 14.1661 19.8802 14.0699Z"
        fill="#225aea"
      />
    </svg>
  );
}
