import React from 'react';
import cls from 'classname';

const NotificationWarning = (props) => (
  <svg
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(props.className, 'icon icon-notification-warning')}
  >
    <rect width="48" height="48" rx="6" fill="#FFFAEC" />
    <g clipPath="url(#clip0)">
      <path
        d="M24 14C22.0222 14 20.0888 14.5865 18.4443 15.6853C16.7998 16.7841 15.5181 18.3459 14.7612 20.1732C14.0043 22.0004 13.8063 24.0111 14.1922 25.9509C14.578 27.8907 15.5304 29.6725 16.9289 31.0711C18.3275 32.4696 20.1093 33.422 22.0491 33.8079C23.9889 34.1937 25.9996 33.9957 27.8268 33.2388C29.6541 32.4819 31.2159 31.2002 32.3147 29.5557C33.4135 27.9112 34 25.9778 34 24C34 22.6868 33.7413 21.3864 33.2388 20.1732C32.7363 18.9599 31.9997 17.8575 31.0711 16.9289C30.1425 16.0003 29.0401 15.2638 27.8268 14.7612C26.6136 14.2587 25.3132 14 24 14ZM24 32C22.4178 32 20.871 31.5308 19.5554 30.6518C18.2398 29.7727 17.2145 28.5233 16.609 27.0615C16.0035 25.5997 15.845 23.9911 16.1537 22.4393C16.4624 20.8874 17.2243 19.462 18.3432 18.3431C19.462 17.2243 20.8874 16.4624 22.4393 16.1537C23.9911 15.845 25.5997 16.0035 27.0615 16.609C28.5233 17.2145 29.7727 18.2398 30.6518 19.5554C31.5308 20.871 32 22.4177 32 24C32 26.1217 31.1572 28.1566 29.6569 29.6569C28.1566 31.1571 26.1217 32 24 32Z"
        fill="#F6B60E"
      />
      <path
        d="M24 21C24.5523 21 25 20.5523 25 20C25 19.4477 24.5523 19 24 19C23.4477 19 23 19.4477 23 20C23 20.5523 23.4477 21 24 21Z"
        fill="#F6B60E"
      />
      <path
        d="M24 22C23.7348 22 23.4804 22.1054 23.2929 22.2929C23.1054 22.4804 23 22.7348 23 23V28C23 28.2652 23.1054 28.5196 23.2929 28.7071C23.4804 28.8946 23.7348 29 24 29C24.2652 29 24.5196 28.8946 24.7071 28.7071C24.8946 28.5196 25 28.2652 25 28V23C25 22.7348 24.8946 22.4804 24.7071 22.2929C24.5196 22.1054 24.2652 22 24 22Z"
        fill="#F6B60E"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NotificationWarning;
