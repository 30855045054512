import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const Database = ({ width, height, className, fill, outline, theme }) =>
  outline ? (
    <svg width={width}
         height={height}
         className={cls(className, 'icon icon-database icon-database-outline')}
         viewBox="0 0 24 24"
         fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.15372 15.5607C1.84504 14.0089 2.00347 12.4003 2.60897 10.9385C3.21447 9.47672 4.23985 8.22729 5.55544 7.34824C6.71227 6.57528 8.04779 6.11922 9.42905 6.0204C10.3309 5.95587 11 6.6922 11 7.5V13L16.5 13C17.3078 13 18.0441 13.6691 17.9796 14.571C17.8808 15.9522 17.4247 17.2877 16.6518 18.4446C15.7727 19.7602 14.5233 20.7855 13.0615 21.391C11.5997 21.9965 9.99113 22.155 8.43928 21.8463C6.88743 21.5376 5.46197 20.7757 4.34315 19.6569C3.22433 18.538 2.4624 17.1126 2.15372 15.5607ZM4.45673 11.7039C4.0026 12.8003 3.88378 14.0067 4.11529 15.1705C4.3468 16.3344 4.91825 17.4035 5.75736 18.2426C6.59648 19.0818 7.66558 19.6532 8.82946 19.8847C9.99335 20.1162 11.1997 19.9974 12.2961 19.5433C13.3925 19.0892 14.3295 18.3201 14.9888 17.3334C15.4611 16.6266 15.7757 15.8308 15.9161 15H11C9.89543 15 9 14.1046 9 13L9 8.08392C8.16919 8.22435 7.37341 8.5389 6.66658 9.01118C5.67989 9.67047 4.91085 10.6075 4.45673 11.7039Z"
            fill="#6e7dae"
      />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13 3.5C13 2.69223 13.6691 1.95587 14.5709 2.0204C15.4261 2.08158 16.2671 2.27993 17.0615 2.60896C18.0321 3.011 18.914 3.60028 19.6569 4.34314C20.3997 5.08601 20.989 5.96793 21.391 6.93853C21.7201 7.73288 21.9184 8.57391 21.9796 9.42905C22.0441 10.3309 21.3078 11 20.5 11H15C13.8954 11 13 10.1046 13 9L13 3.5ZM15 4.08392L15 9H19.9161C19.8411 8.55637 19.7163 8.12153 19.5433 7.7039C19.2417 6.97594 18.7998 6.31451 18.2426 5.75736C17.6855 5.20021 17.0241 4.75825 16.2961 4.45672C15.8785 4.28373 15.4436 4.15891 15 4.08392Z"
            fill="#6e7dae"
      />
    </svg>
  ) : (
    <svg width={width}
         height={height}
         className={cls(className, 'icon icon-database icon-database-bold')}
         viewBox="0 0 24 24"
         xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4.99987C11 4.44758 10.5509 3.99412 10.002 4.05536C8.57508 4.21457 7.20174 4.71357 5.99987 5.51664C4.51983 6.50557 3.36628 7.91118 2.68509 9.55572C2.0039 11.2003 1.82567 13.0099 2.17294 14.7557C2.5202 16.5015 3.37737 18.1052 4.63604 19.3638C5.89472 20.6225 7.49836 21.4797 9.24419 21.8269C10.99 22.1742 12.7996 21.996 14.4442 21.3148C16.0887 20.6336 17.4943 19.48 18.4832 18C19.2863 16.7981 19.7853 15.4248 19.9445 13.9978C20.0057 13.4489 19.5523 12.9999 19 12.9999H12C11.4477 12.9999 11 12.5522 11 11.9999V4.99987Z"
        />
      <path
        d="M21 10.9999C21.5523 10.9999 22.0057 10.5508 21.9445 10.0019C21.8509 9.16262 21.6394 8.33899 21.3149 7.55571C20.8626 6.46378 20.1997 5.47163 19.364 4.6359C18.5282 3.80017 17.5361 3.13724 16.4442 2.68495C15.6609 2.3605 14.8372 2.149 13.998 2.05536C13.4491 1.99412 13 2.44758 13 2.99986V9.99986C13 10.5521 13.4477 10.9999 14 10.9999H21Z"
        />
    </svg>
  );

Database.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

Database.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default Database;
