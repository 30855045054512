import React from 'react';
import cls from 'classname';

const DatabaseProductImages = (props) => (
  <svg width={props.width ? props.width : '24'}
       height={props.height ? props.height : '24'}
       fill={props.color ? props.color : '#707BA0'}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
       className={cls(props.className, 'icon icon-database-filter')}
  >
    <path
      d="M3.20521 17.3127C3.13243 16.9958 3.08787 16.6627 3.05894 16.3086C3.0293 15.9458 3.01485 15.5393 3.00781 15.0833L5.35096 13.6345L5.4296 13.5857C5.7593 13.381 6.09089 13.1751 6.46445 13.079C6.79055 12.9951 7.13022 12.9779 7.4631 13.0286C7.84445 13.0866 8.19509 13.258 8.54372 13.4285L8.62687 13.4691L9.15523 13.7262C9.68462 13.9838 9.74776 14.001 9.78994 14.0065C9.86113 14.0159 9.93351 14.0099 10.0021 13.9888C10.0428 13.9763 10.1022 13.9489 10.5814 13.6068L13.087 11.8177L13.1801 11.751C13.5644 11.4754 13.95 11.1988 14.395 11.08C14.7825 10.9766 15.1893 10.9679 15.5809 11.0548C16.0305 11.1545 16.4275 11.4145 16.8231 11.6736L16.919 11.7363L21.0003 14.3959C20.9977 15.1414 20.9871 15.7727 20.9433 16.3086C20.9319 16.4484 20.918 16.5849 20.901 16.7184L15.8271 13.4119C15.2644 13.0452 15.1937 13.0175 15.1478 13.0073C15.0695 12.99 14.9881 12.9917 14.9106 13.0124C14.8652 13.0245 14.7957 13.0552 14.2492 13.4454L11.7435 15.2344L11.6623 15.2926C11.3236 15.5355 10.9831 15.7796 10.5897 15.9005C10.2465 16.006 9.8846 16.0363 9.52864 15.9894C9.12056 15.9356 8.74423 15.7515 8.36981 15.5684L8.28005 15.5246L7.75169 15.2674C7.26009 15.0282 7.20207 15.0119 7.16244 15.0058C7.09586 14.9957 7.02792 14.9991 6.96271 15.0159C6.92388 15.0259 6.8678 15.048 6.40279 15.3355L3.20521 17.3127Z"
    />
    <path
      d="M11.0011 9C11.0011 10.1046 10.1057 11 9.00113 11C7.89656 11 7.00113 10.1046 7.00113 9C7.00113 7.89543 7.89656 7 9.00113 7C10.1057 7 11.0011 7.89543 11.0011 9Z"
    />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M10.3572 3H13.6428C14.7266 2.99999 15.6007 2.99998 16.3086 3.05782C17.0375 3.11737 17.6777 3.24318 18.27 3.54497C19.2108 4.02433 19.9757 4.78924 20.455 5.73005C20.7568 6.32234 20.8826 6.96253 20.9422 7.69138C21 8.39925 21 9.27339 21 10.3572V13.6428C21 14.7266 21 15.6008 20.9422 16.3086C20.8826 17.0375 20.7568 17.6777 20.455 18.27C19.9757 19.2108 19.2108 19.9757 18.27 20.455C17.6777 20.7568 17.0375 20.8826 16.3086 20.9422C15.6008 21 14.7266 21 13.6428 21H10.3572C9.27339 21 8.39925 21 7.69138 20.9422C6.96253 20.8826 6.32234 20.7568 5.73005 20.455C4.78924 19.9757 4.02433 19.2108 3.54497 18.27C3.24318 17.6777 3.11737 17.0375 3.05782 16.3086C2.99998 15.6007 2.99999 14.7266 3 13.6428V10.3572C2.99999 9.27341 2.99998 8.39926 3.05782 7.69138C3.11737 6.96253 3.24318 6.32234 3.54497 5.73005C4.02433 4.78924 4.78924 4.02433 5.73005 3.54497C6.32234 3.24318 6.96253 3.11737 7.69138 3.05782C8.39926 2.99998 9.27341 2.99999 10.3572 3ZM7.85424 5.05118C7.24907 5.10062 6.90138 5.19279 6.63803 5.32698C6.07354 5.6146 5.6146 6.07354 5.32698 6.63803C5.19279 6.90138 5.10062 7.24907 5.05118 7.85424C5.00078 8.47108 5 9.26339 5 10.4V13.6C5 14.7366 5.00078 15.5289 5.05118 16.1458C5.10062 16.7509 5.19279 17.0986 5.32698 17.362C5.6146 17.9265 6.07354 18.3854 6.63803 18.673C6.90138 18.8072 7.24907 18.8994 7.85424 18.9488C8.47108 18.9992 9.26339 19 10.4 19H13.6C14.7366 19 15.5289 18.9992 16.1458 18.9488C16.7509 18.8994 17.0986 18.8072 17.362 18.673C17.9265 18.3854 18.3854 17.9265 18.673 17.362C18.8072 17.0986 18.8994 16.7509 18.9488 16.1458C18.9992 15.5289 19 14.7366 19 13.6V10.4C19 9.26339 18.9992 8.47108 18.9488 7.85424C18.8994 7.24907 18.8072 6.90138 18.673 6.63803C18.3854 6.07354 17.9265 5.6146 17.362 5.32698C17.0986 5.19279 16.7509 5.10062 16.1458 5.05118C15.5289 5.00078 14.7366 5 13.6 5H10.4C9.26339 5 8.47108 5.00078 7.85424 5.05118Z"
    />
  </svg>
);
export default DatabaseProductImages;
