import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const CartUpcoming = ({ width, height, className, fill, outline }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    className={cls(
      className,
      'icon icon-cart-upcoming icon-cart-upcoming-outline',
    )}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.36838 3.29989C2.95101 3.03776 2.40016 3.1636 2.13803 3.58097C1.87589 3.99834 2.00174 4.54919 2.4191 4.81132L3.97811 5.79048L4.31532 9.55955C4.32047 9.6172 4.33101 9.67309 4.34638 9.72675L4.70919 14.4797C4.84374 16.2424 6.3133 17.6041 8.08117 17.6041H16.9783C18.4744 17.6041 19.7927 16.6211 20.2195 15.1872L21.9086 9.51399C22.3165 8.14374 21.29 6.7671 19.8603 6.7671H6.40151C6.09348 6.7671 5.83639 6.53198 5.80894 6.22517L5.74267 5.4845C5.70126 5.02161 5.44586 4.60469 5.05231 4.35751L3.36838 3.29989ZM6.68877 8.5519C6.34236 8.5519 6.0692 8.84671 6.09556 9.19212L6.48882 14.3438C6.55236 15.1763 7.24633 15.8193 8.08117 15.8193H16.9783C17.6848 15.8193 18.3073 15.3551 18.5089 14.678L20.1979 9.00472C20.2652 8.77883 20.096 8.5519 19.8603 8.5519H6.68877ZM7.40846 20.8367C8.06509 20.8367 8.59738 20.3044 8.59738 19.6478C8.59738 18.9912 8.06509 18.4589 7.40846 18.4589C6.75184 18.4589 6.21955 18.9912 6.21955 19.6478C6.21955 20.3044 6.75184 20.8367 7.40846 20.8367ZM18.276 19.6478C18.276 20.3044 17.7437 20.8367 17.0871 20.8367C16.4305 20.8367 15.8982 20.3044 15.8982 19.6478C15.8982 18.9912 16.4305 18.4589 17.0871 18.4589C17.7437 18.4589 18.276 18.9912 18.276 19.6478ZM15.1419 9.45881C14.649 9.45881 14.2495 9.85835 14.2495 10.3512C14.2495 10.8441 14.649 11.2436 15.1419 11.2436H17.5399C18.0327 11.2436 18.4323 10.8441 18.4323 10.3512C18.4323 9.85835 18.0327 9.45881 17.5399 9.45881H15.1419Z"
      fill="#707BA0"
    />
  </svg>
);

CartUpcoming.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

CartUpcoming.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default CartUpcoming;
