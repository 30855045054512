import React from 'react';
import cls from 'classname';

const DatabaseDomain = (props) => (
  <svg width={props.width ? props.width : '24'}
       height={props.height ? props.height : '24'}
       fill={props.color ? props.color : '#707BA0'}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
       className={cls(props.className, 'icon icon-database-filter')}
  >
    <path
      d="M18.7969 3H6.79688C6.00123 3 5.23816 3.31607 4.67555 3.87868C4.11295 4.44129 3.79688 5.20435 3.79688 6V18C3.79688 18.7956 4.11295 19.5587 4.67555 20.1213C5.23816 20.6839 6.00123 21 6.79688 21H18.7969C19.5925 21 20.3556 20.6839 20.9182 20.1213C21.4808 19.5587 21.7969 18.7956 21.7969 18V6C21.7969 5.20435 21.4808 4.44129 20.9182 3.87868C20.3556 3.31607 19.5925 3 18.7969 3ZM19.7969 18C19.7969 18.2652 19.6915 18.5196 19.504 18.7071C19.3164 18.8946 19.0621 19 18.7969 19H6.79688C6.53166 19 6.2773 18.8946 6.08977 18.7071C5.90223 18.5196 5.79688 18.2652 5.79688 18V11H19.7969V18ZM5.79688 9V6C5.79688 5.73478 5.90223 5.48043 6.08977 5.29289C6.2773 5.10536 6.53166 5 6.79688 5H18.7969C19.0621 5 19.3164 5.10536 19.504 5.29289C19.6915 5.48043 19.7969 5.73478 19.7969 6V9H5.79688Z"
    />
    <path
      d="M8.79688 8.03003C9.34916 8.03003 9.79688 7.58231 9.79688 7.03003C9.79688 6.47774 9.34916 6.03003 8.79688 6.03003C8.24459 6.03003 7.79688 6.47774 7.79688 7.03003C7.79688 7.58231 8.24459 8.03003 8.79688 8.03003Z"
    />
    <path
      d="M12.7969 8.03003C13.3492 8.03003 13.7969 7.58231 13.7969 7.03003C13.7969 6.47774 13.3492 6.03003 12.7969 6.03003C12.2446 6.03003 11.7969 6.47774 11.7969 7.03003C11.7969 7.58231 12.2446 8.03003 12.7969 8.03003Z"
    />
  </svg>
);
export default DatabaseDomain;
