import React from 'react';
import cls from 'classname';

const NotificationDelete = (props) => (
  <svg
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(props.className, 'icon icon-notification-delete')}
  >
    <rect width="48" height="48" rx="6" fill="#FFF4F4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.2871 17.243C32.6761 17.243 33 17.566 33 17.977V18.357C33 18.758 32.6761 19.0909 32.2871 19.0909H15.7139C15.3239 19.0909 15 18.758 15 18.357V17.977C15 17.566 15.3239 17.243 15.7139 17.243H18.6296C19.2218 17.243 19.7373 16.822 19.8705 16.228L20.0232 15.546C20.2605 14.617 21.0415 14 21.9353 14H26.0647C26.9488 14 27.7385 14.617 27.967 15.497L28.1304 16.227C28.2627 16.822 28.7781 17.243 29.3714 17.243H32.2871ZM30.8058 31.134C31.1102 28.2971 31.6432 21.5571 31.6432 21.4891C31.6626 21.2831 31.5955 21.0881 31.4623 20.9311C31.3193 20.7841 31.1384 20.6971 30.9391 20.6971H17.0685C16.8682 20.6971 16.6776 20.7841 16.5453 20.9311C16.4111 21.0881 16.3449 21.2831 16.3547 21.4891C16.3565 21.5016 16.3756 21.739 16.4076 22.1359C16.5496 23.8992 16.9452 28.8102 17.2008 31.134C17.3817 32.846 18.505 33.922 20.1321 33.961C21.3876 33.99 22.6811 34 24.0038 34C25.2496 34 26.5149 33.99 27.8094 33.961C29.4929 33.932 30.6152 32.875 30.8058 31.134Z"
      fill="#D71313"
    />
  </svg>
);

export default NotificationDelete;
