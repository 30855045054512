import React from 'react';

export default function ING(props) {
  return (
    <svg width={props.width ? props.width : '45'}
         height={props.height ? props.height : '15'}
         viewBox="0 0 45 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_19082_272202)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.485311 0.816166C1.07256 0.816166 1.48178 0.939002 1.7359 1.18997C2.02026 1.47081 2.14756 1.9347 2.14756 2.69388V12.1764C2.14756 13.4886 1.86906 14.0739 0.22534 14.0739H0V14.6558H7.38697V14.0768L7.16406 14.0739C5.53596 14.0546 5.26135 13.4727 5.26135 12.1764V2.6934C5.26135 1.93663 5.38817 1.47467 5.67253 1.19431C5.92908 0.939484 6.34562 0.815684 6.94506 0.815684H7.38648V0.225586H0V0.815684H0.485311V0.816166Z" fill="white" fillOpacity="0.7"/>
        <path d="M0.2 0.615684H0.485311H0.685311V0.621022C1.19779 0.646775 1.60188 0.776513 1.87644 1.04768C2.22113 1.3881 2.34756 1.92396 2.34756 2.69388V12.1764C2.34756 12.8305 2.28265 13.3774 1.95251 13.7499C1.61691 14.1285 1.05811 14.2739 0.22534 14.2739H0.2V14.4558H7.18697V14.2742L7.1617 14.2738L7.16147 14.2738L0.2 0.615684ZM0.2 0.615684V0.425586H7.18648V0.615684H6.94506C6.32612 0.615684 5.84439 0.742112 5.53211 1.05189L0.2 0.615684Z" stroke="url(#paint0_linear_19082_272202)" strokeOpacity="0.8" strokeWidth="0.4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M24.1115 9.81789C24.1115 9.81789 23.9832 9.22587 23.9647 8.89445V4.56337C23.9359 2.03775 24.2847 0.78048 22.0488 0.790114V0.222656H27.1921V0.790114C24.8895 0.780962 25.1792 2.03823 25.166 4.56337V14.6764L24.4339 14.6687L13.3274 3.18953C13.3274 3.18953 13.4522 3.78059 13.4708 4.11249V11.2047C13.4079 13.792 13.7322 14.1047 15.4198 14.0743V14.6654H10.058V14.0743C11.748 14.0762 12.0802 13.8542 12.0782 10.9938V11.1648V4.17511L12.0646 3.06573C12.1275 1.62974 11.5905 0.796858 9.95117 0.810346V0.242407H14.8877L24.1115 9.81789Z" fill="white" fillOpacity="0.7"/>
        <path d="M23.9675 9.95664L24.4561 10.4639L24.307 9.77555L24.307 9.77554L24.307 9.7755L24.3069 9.77524L24.3067 9.7741L24.3056 9.76936L24.3017 9.75061C24.2982 9.73419 24.2932 9.71025 24.2872 9.68043C24.2751 9.62074 24.2587 9.53777 24.2418 9.44473C24.2079 9.25789 24.1737 9.04068 24.1647 8.88869L24.1647 4.56337L24.1647 4.56109C24.1611 4.24762 24.1634 3.95355 24.1658 3.67746L24.1662 3.6356C24.1685 3.3759 24.1706 3.13126 24.1676 2.90429C24.1613 2.42974 24.1326 2.01082 24.0254 1.66654C23.9152 1.31285 23.7198 1.03095 23.3841 0.845418C23.0976 0.68702 22.7246 0.607817 22.2488 0.592742V0.422656H26.9921V0.592603C26.4995 0.607303 26.1122 0.686386 25.8129 0.843693C25.4645 1.02685 25.254 1.30606 25.1315 1.66113C25.0122 2.00672 24.9751 2.42678 24.9644 2.90271C24.9583 3.17205 24.9608 3.47233 24.9634 3.79549C24.9653 4.03924 24.9674 4.29601 24.966 4.56233V4.56337V14.4743L24.5196 14.4696L13.4711 3.05046L12.9881 2.55127L13.1317 3.23085L13.1317 3.23086L13.1317 3.23086L13.1317 3.23091L13.1318 3.23116L13.132 3.23231L13.133 3.23705L13.1369 3.25578C13.1402 3.27219 13.1451 3.29611 13.151 3.3259C13.1628 3.38554 13.1787 3.46844 13.1952 3.56144C13.2283 3.74809 13.2617 3.96555 13.2708 4.11823V11.2023C13.2551 11.85 13.2632 12.3659 13.3129 12.7731C13.3625 13.1792 13.4556 13.4987 13.6261 13.7374C13.8022 13.9839 14.0451 14.1228 14.3468 14.1968C14.5924 14.2571 14.8835 14.276 15.2198 14.2762V14.4654H10.258V14.2733C10.5944 14.2691 10.8871 14.2503 11.1341 14.1904C11.4379 14.1166 11.6843 13.9778 11.8655 13.7234C12.0404 13.4779 12.1404 13.1439 12.1993 12.7089C12.2536 12.3072 12.2752 11.8005 12.2779 11.1648H12.2782V11.0436C12.2783 11.027 12.2783 11.0104 12.2782 10.9936V4.17511H12.2783L12.2782 4.17265L12.2646 3.06883C12.2961 2.33378 12.1773 1.70622 11.798 1.26423C11.4442 0.852025 10.8975 0.639253 10.1512 0.612968V0.442407H14.8026L23.9675 9.95664Z" stroke="url(#paint1_linear_19082_272202)" strokeOpacity="0.8" strokeWidth="0.4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M42.7309 -0.0527344H42.1642L42.1378 0.138987C42.0993 0.425124 41.9852 0.904428 41.655 0.904428C41.5608 0.904428 41.3262 0.860593 41.0619 0.741128C39.9137 0.249781 38.4261 -0.0527344 37.1735 -0.0527344C34.7362 -0.0527344 32.5253 0.809531 30.9459 2.37606C29.4954 3.81542 28.7018 5.77454 28.713 7.89167C28.7233 9.91968 29.5305 11.5879 31.183 12.994C32.8477 14.4006 34.7128 15.0003 37.4232 15.0003C39.681 15.0003 40.9736 14.68 42.9572 13.6284L43.3045 13.4424V9.65859C43.3045 8.04582 43.5045 7.79485 44.8331 7.74186L45.0492 7.73367V7.17151H38.5582V7.73367L38.7743 7.74186C40.2541 7.7987 40.5336 8.06075 40.5336 9.39413V12.9005C39.4357 13.6159 38.6377 13.8875 37.6315 13.8875C36.1424 13.8875 34.8172 13.3191 33.7997 12.3152C32.6082 11.1375 32.0268 9.42207 32.0268 7.48366C32.0268 5.39544 32.5896 3.61406 33.7495 2.46951C34.6728 1.55763 35.899 1.05135 37.2979 1.05135C38.5475 1.05135 39.999 1.58075 40.9945 2.36065C41.8042 3.01529 42.2242 3.64875 42.5412 4.69551L42.5895 4.85399H43.1436L42.7309 -0.0527344Z" fill="white" fillOpacity="0.7"/>
        <path d="M28.913 7.89061L28.913 7.89066C28.9229 9.85584 29.7009 11.4701 31.3123 12.8414C32.9327 14.2105 34.7483 14.8003 37.4232 14.8003C38.5426 14.8003 39.4128 14.7209 40.2449 14.5146C41.0769 14.3083 41.8801 13.973 42.8633 13.4518C42.8634 13.4518 42.8635 13.4517 42.8636 13.4517L43.1045 13.3227V9.65859C43.1045 9.25385 43.1169 8.92261 43.159 8.65509C43.2011 8.38749 43.2756 8.16447 43.4142 7.99061C43.556 7.81283 43.7475 7.7078 43.9811 7.64398C44.2098 7.58147 44.4905 7.55536 44.8252 7.54202L44.8256 7.542L44.8492 7.54111V7.37151H38.7582V7.54111L38.7819 7.542L38.782 7.54201C39.1551 7.55634 39.465 7.58387 39.7179 7.6415C39.972 7.6994 40.1833 7.79087 40.3458 7.94349C40.671 8.24886 40.7336 8.73542 40.7336 9.39413V12.9005V13.0089L40.6428 13.0681C39.5221 13.7983 38.6867 14.0875 37.6315 14.0875C36.0886 14.0875 34.7133 13.4976 33.6593 12.4576L33.6592 12.4575C32.4198 11.2325 31.8268 9.46008 31.8268 7.48366C31.8268 5.36441 32.3976 3.52262 33.609 2.32721C34.571 1.37707 35.8483 0.851351 37.2979 0.851351C38.5952 0.851351 40.0896 1.39764 41.1179 2.20321L41.1203 2.2051L41.1203 2.20512C41.9626 2.88618 42.4045 3.55426 42.7325 4.63722L28.913 7.89061ZM28.913 7.89061C28.9021 5.82504 29.6758 3.91811 31.0868 2.51803C32.6249 0.992404 34.7835 0.147266 37.1735 0.147266C38.3986 0.147266 39.8586 0.444083 40.9814 0.924227C41.2621 1.05079 41.5244 1.10443 41.655 1.10443C41.9375 1.10443 42.1003 0.894333 42.1861 0.715501C42.2743 0.531517 42.3159 0.315494 42.336 0.165679L42.3386 0.147266H42.547L42.926 4.65399H42.7376M28.913 7.89061L42.7376 4.65399M42.7376 4.65399L42.7326 4.63753L42.7376 4.65399Z" stroke="url(#paint2_linear_19082_272202)" strokeOpacity="0.8" strokeWidth="0.4"/>
      </g>
      <defs>
        <linearGradient id="paint0_linear_19082_272202" x1="6.51791" y1="18.2633" x2="0.894884" y2="4.98603" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="0.330961" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_19082_272202" x1="25.1638" y1="18.2899" x2="22.4043" y2="3.10714" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="0.330961" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_19082_272202" x1="43.1273" y1="18.7636" x2="39.9894" y2="3.05586" gradientUnits="userSpaceOnUse">
          <stop stopColor="white"/>
          <stop offset="0.330961" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <clipPath id="clip0_19082_272202">
          <rect width="45" height="15" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
}
