import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classname';

export const MethodLink = ({ width, height, className, color }) => (
  <svg width={width}
       height={height}
       fill={color}
       viewBox="0 0 64 64"
       xmlns="http://www.w3.org/2000/svg"
       className={cls(className, 'icon ')}
  >
    <path fillRule="evenodd" clipRule="evenodd"
          d="M55.7843 8.21566C48.8301 1.26145 37.5551 1.26145 30.6009 8.21566L25.0046 13.812C24.2319 14.5847 24.2319 15.8374 25.0046 16.6101C25.7773 17.3828 27.0301 17.3828 27.8028 16.6101L33.3991 11.0138C38.8079 5.60499 47.5774 5.60499 52.9862 11.0138C58.395 16.4226 58.395 25.1921 52.9862 30.6009L47.3899 36.1972C46.6172 36.9699 46.6172 38.2227 47.3899 38.9954C48.1626 39.7681 49.4153 39.7681 50.188 38.9954L55.7843 33.3991C62.7386 26.4449 62.7386 15.1699 55.7843 8.21566ZM13.812 30.6009C14.5847 29.8282 14.5847 28.5755 13.812 27.8028C13.0393 27.0301 11.7865 27.0301 11.0138 27.8028L8.21566 30.6009C1.26145 37.5551 1.26145 48.8301 8.21566 55.7843C15.1699 62.7386 26.4449 62.7386 33.3991 55.7843L36.1972 52.9862C36.9699 52.2135 36.9699 50.9607 36.1972 50.188C35.4245 49.4153 34.1718 49.4153 33.3991 50.188L30.6009 52.9862C25.1921 58.395 16.4226 58.395 11.0138 52.9862C5.60498 47.5774 5.60498 38.8079 11.0138 33.3991L13.812 30.6009ZM40.4921 26.3049C41.2648 25.5322 41.2648 24.2794 40.4921 23.5067C39.7194 22.734 38.4666 22.734 37.6939 23.5067L26.3041 34.8966C25.5314 35.6693 25.5314 36.922 26.3041 37.6947C27.0768 38.4674 28.3295 38.4674 29.1022 37.6947L40.4921 26.3049Z"
    />
  </svg>
);

MethodLink.defaultProps = {
  width: 64,
  height: 64,
  color: '#225aea'
};

MethodLink.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  color: PropTypes.string,
};

export default MethodLink;
