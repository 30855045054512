import React from 'react';
import cls from 'classname';

const NotificationError = (props) => (
  <svg
    width={props.width ? props.width : '48'}
    height={props.height ? props.height : '48'}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={cls(props.className, 'icon icon-notification-error')}
  >
    <rect width="48" height="48" rx="6" fill="#FFF4F4" />
    <path
      d="M24 14C22.0222 14 20.0888 14.5865 18.4443 15.6853C16.7998 16.7841 15.5181 18.3459 14.7612 20.1732C14.0043 22.0004 13.8063 24.0111 14.1922 25.9509C14.578 27.8907 15.5304 29.6725 16.9289 31.0711C18.3275 32.4696 20.1093 33.422 22.0491 33.8079C23.9889 34.1937 25.9996 33.9957 27.8268 33.2388C29.6541 32.4819 31.2159 31.2002 32.3147 29.5557C33.4135 27.9112 34 25.9778 34 24C34 22.6868 33.7413 21.3864 33.2388 20.1732C32.7363 18.9599 31.9997 17.8575 31.0711 16.9289C30.1425 16.0003 29.0401 15.2638 27.8268 14.7612C26.6136 14.2587 25.3132 14 24 14ZM24 32C22.4178 32 20.871 31.5308 19.5554 30.6518C18.2398 29.7727 17.2145 28.5233 16.609 27.0615C16.0035 25.5997 15.845 23.9911 16.1537 22.4393C16.4624 20.8874 17.2243 19.462 18.3432 18.3431C19.462 17.2243 20.8874 16.4624 22.4393 16.1537C23.9911 15.845 25.5997 16.0035 27.0615 16.609C28.5233 17.2145 29.7727 18.2398 30.6518 19.5554C31.5308 20.871 32 22.4177 32 24C32 26.1217 31.1572 28.1566 29.6569 29.6569C28.1566 31.1571 26.1217 32 24 32Z"
      fill="#D71313"
    />
    <path
      d="M26.71 21.2899C26.617 21.1962 26.5064 21.1218 26.3846 21.071C26.2627 21.0203 26.132 20.9941 26 20.9941C25.868 20.9941 25.7373 21.0203 25.6154 21.071C25.4936 21.1218 25.383 21.1962 25.29 21.2899L24 22.5899L22.71 21.2899C22.5217 21.1016 22.2663 20.9958 22 20.9958C21.7337 20.9958 21.4783 21.1016 21.29 21.2899C21.1017 21.4782 20.9959 21.7336 20.9959 21.9999C20.9959 22.2662 21.1017 22.5216 21.29 22.7099L22.59 23.9999L21.29 25.2899C21.1963 25.3829 21.1219 25.4935 21.0711 25.6154C21.0203 25.7372 20.9942 25.8679 20.9942 25.9999C20.9942 26.132 21.0203 26.2627 21.0711 26.3845C21.1219 26.5064 21.1963 26.617 21.29 26.7099C21.383 26.8037 21.4936 26.8781 21.6154 26.9288C21.7373 26.9796 21.868 27.0057 22 27.0057C22.132 27.0057 22.2627 26.9796 22.3846 26.9288C22.5064 26.8781 22.617 26.8037 22.71 26.7099L24 25.4099L25.29 26.7099C25.383 26.8037 25.4936 26.8781 25.6154 26.9288C25.7373 26.9796 25.868 27.0057 26 27.0057C26.132 27.0057 26.2627 26.9796 26.3846 26.9288C26.5064 26.8781 26.617 26.8037 26.71 26.7099C26.8037 26.617 26.8781 26.5064 26.9289 26.3845C26.9797 26.2627 27.0058 26.132 27.0058 25.9999C27.0058 25.8679 26.9797 25.7372 26.9289 25.6154C26.8781 25.4935 26.8037 25.3829 26.71 25.2899L25.41 23.9999L26.71 22.7099C26.8037 22.617 26.8781 22.5064 26.9289 22.3845C26.9797 22.2627 27.0058 22.132 27.0058 21.9999C27.0058 21.8679 26.9797 21.7372 26.9289 21.6154C26.8781 21.4935 26.8037 21.3829 26.71 21.2899Z"
      fill="#D71313"
    />
  </svg>
);

export default NotificationError;
