import React from 'react';
import cls from 'classname';

const DatabaseVariants = (props) => (
  <svg width={props.width ? props.width : '24'}
       height={props.height ? props.height : '24'}
       fill={props.color ? props.color : '#707BA0'}
       viewBox="0 0 24 24"
       xmlns="http://www.w3.org/2000/svg"
       className={cls(props.className, 'icon icon-database-filter')}
  >
    <path d="M19 4C19.5523 4 20 4.44772 20 5V7C20 7.55229 19.5523 8 19 8C18.4477 8 18 7.55229 18 7V5C18 4.44772 18.4477 4 19 4Z"/>
    <path
      d="M13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19L13 17Z"/>
    <path d="M12 4C12.5523 4 13 4.44772 13 5V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V5C11 4.44772 11.4477 4 12 4Z"/>
    <path d="M6 5C6 4.44772 5.55229 4 5 4C4.44772 4 4 4.44772 4 5L4 9C4 9.55229 4.44772 10 5 10C5.55229 10 6 9.55229 6 9L6 5Z"/>
    <path d="M6 13C6 12.4477 5.55229 12 5 12C4.44772 12 4 12.4477 4 13L4 19C4 19.5523 4.44772 20 5 20C5.55229 20 6 19.5523 6 19L6 13Z"/>
    <path
      d="M20 11C20 10.4477 19.5523 10 19 10C18.4477 10 18 10.4477 18 11L18 19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V11Z"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M19 12C17.3431 12 16 10.6569 16 9C16 7.34315 17.3431 6 19 6C20.6569 6 22 7.34315 22 9C22 10.6569 20.6569 12 19 12ZM19 10C18.4477 10 18 9.55228 18 9C18 8.44772 18.4477 8 19 8C19.5523 8 20 8.44772 20 9C20 9.55228 19.5523 10 19 10Z"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M9 15C9 16.6569 10.3431 18 12 18C13.6569 18 15 16.6569 15 15C15 13.3431 13.6569 12 12 12C10.3431 12 9 13.3431 9 15ZM11 15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15C13 14.4477 12.5523 14 12 14C11.4477 14 11 14.4477 11 15Z"/>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2 11C2 12.6569 3.34315 14 5 14C6.65685 14 8 12.6569 8 11C8 9.34315 6.65685 8 5 8C3.34315 8 2 9.34315 2 11ZM4 11C4 11.5523 4.44772 12 5 12C5.55228 12 6 11.5523 6 11C6 10.4477 5.55228 10 5 10C4.44772 10 4 10.4477 4 11Z"/>
  </svg>
);
export default DatabaseVariants;
