import React from 'react';
import cls from 'classname';

const Email = ({width, height, color, className, style, ...props}) => {

  return (
    <svg width={width ? width : '24'}
         height={height ? height : '24'}
         viewBox="0 0 24 24"
         fill={color ? color : '#707BA0'}
         style={style}
         className={cls(className, 'icon icon-lock')} xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1" fill="white">
        <path
          d="M2 7.72122C2 5.17319 4.06559 3.1076 6.61362 3.1076H17.3878C19.9359 3.1076 22.0015 5.17319 22.0015 7.72122V16.2788C22.0015 18.8268 19.9359 20.8924 17.3878 20.8924H6.61362C4.06559 20.8924 2 18.8268 2 16.2788V7.72122Z"/>
      </mask>
      <path
        d="M6.61362 5.08487H17.3878V1.13034H6.61362V5.08487ZM20.0242 7.72122V16.2788H23.9787V7.72122H20.0242ZM17.3878 18.9151H6.61362V22.8697H17.3878V18.9151ZM3.97727 16.2788V7.72122H0.022735V16.2788H3.97727ZM6.61362 18.9151C5.1576 18.9151 3.97727 17.7348 3.97727 16.2788H0.022735C0.022735 19.9188 2.97358 22.8697 6.61362 22.8697V18.9151ZM20.0242 16.2788C20.0242 17.7348 18.8439 18.9151 17.3878 18.9151V22.8697C21.0279 22.8697 23.9787 19.9188 23.9787 16.2788H20.0242ZM17.3878 5.08487C18.8439 5.08487 20.0242 6.26521 20.0242 7.72122H23.9787C23.9787 4.08118 21.0279 1.13034 17.3878 1.13034V5.08487ZM6.61362 1.13034C2.97358 1.13034 0.022735 4.08118 0.022735 7.72122H3.97727C3.97727 6.2652 5.1576 5.08487 6.61362 5.08487V1.13034Z"
        fill={color ? color : '#707BA0'} mask="url(#path-1-inside-1)"/>
      <path
        d="M6.12165 7.82923C5.674 7.60992 5.15195 7.83749 4.95562 8.33751C4.75928 8.83754 4.96301 9.42068 5.41065 9.63999L11.6438 12.6937C11.6484 12.696 11.653 12.6982 11.6576 12.7004C11.77 12.7535 11.8869 12.7786 12.002 12.7786C12.1171 12.7786 12.234 12.7535 12.3465 12.7004C12.351 12.6982 12.3556 12.696 12.3602 12.6938L18.5934 9.63999C19.041 9.42068 19.2447 8.83754 19.0484 8.33751C18.8521 7.83749 18.33 7.60992 17.8824 7.82923L12.582 10.426C12.2161 10.6053 11.7879 10.6053 11.4221 10.426L6.12165 7.82923Z"
        fill={color ? color : '#707BA0'} />
    </svg>

  );
};

export default Email;
