import React from 'react';
import PropTypes from 'prop-types';

export const BoostersMobile = ({
  width,
  height,
  className,
  fill,
  outline,
  theme,
}) =>
  outline ? (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5465 17.9754C20.5465 18.7785 20.0506 19.4834 19.3837 19.9309C17.2495 21.3629 15.7999 23.9806 15.7999 26.8397C15.7999 31.3586 19.567 34.9 23.9051 34.9C28.9051 34.9 32.2001 31 32.2001 26.8397C32.2001 23.9632 30.7327 21.2574 28.3942 19.8103C27.6897 19.3744 27.1637 18.6531 27.1637 17.8246V14.0493H20.5465C20.5465 14.0493 20.5465 16.1873 20.5465 17.9754Z"
        stroke="#707BA0"
        strokeWidth="1.98"
      />
      <path
        d="M18.8031 26.4124C20.1788 28.2283 23.2828 28.1606 23.9603 25.7806C24.9343 22.3595 29.4206 22.9408 29.4206 26.4992C29.4206 29.5171 27.5001 31.9602 23.9603 31.9602C20.9428 31.9602 18.5001 29.5171 18.5001 26.4992C18.5001 26.356 18.7167 26.2983 18.8031 26.4124Z"
        fill="#707BA0"
      />
      <rect
        x="18.7059"
        y="12.8572"
        width="10.4575"
        height="2.08696"
        rx="1"
        fill="#707BA0"
      />
    </svg>
  ) : (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3952 8.40902C11.3952 9.21215 10.904 9.92067 10.2171 10.3369C7.16917 12.1842 5.06641 15.8102 5.06641 19.7863C5.06641 25.8115 10.0893 30.5333 15.8734 30.5333C22.5401 30.5333 26.9333 25.3333 26.9333 19.7863C26.9333 15.7913 24.8106 12.0433 21.4635 10.1817C20.7395 9.77899 20.2182 9.05305 20.2182 8.22463V2.73242H11.3952C11.3952 2.73242 11.3952 5.95153 11.3952 8.40902Z"
        fill="white"
        stroke="white"
      />
      <path
        d="M9.0706 19.2165C10.9048 21.6377 15.0434 21.5475 15.9469 18.3741C17.2454 13.8126 23.2272 14.5877 23.2272 19.3321C23.2272 23.3561 20.6665 26.6136 15.9469 26.6136C11.9235 26.6136 8.6665 23.3561 8.6665 19.3321C8.6665 19.1412 8.95532 19.0643 9.0706 19.2165Z"
        fill="#225aea"
      />
      <rect
        x="8.94092"
        y="1.14282"
        width="13.9434"
        height="2.78261"
        rx="1"
        fill="white"
      />
    </svg>
  );

BoostersMobile.defaultProps = {
  width: 24,
  height: 24,
  // fill: '#6E7DAE',
  outline: true,
};

BoostersMobile.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  fill: PropTypes.string,
  outline: PropTypes.bool,
};

export default BoostersMobile;
